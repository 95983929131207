const appConfig = {
    // apiPrefix: 'https:///api.aws.inf.br/despbox',
    apiPrefix: process.env.REACT_APP_API_PREFIX,
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
